import React, { FC } from 'react';
import { HorizontalGroup, LinkButton, stylesFactory, useTheme } from '@grafana/ui';
import { GrafanaTheme } from '@grafana/data';
import { css } from 'emotion';

export interface Props {
  folderId?: number;
  isEditor: boolean;
  canEdit?: boolean;
}

export const DashboardActions: FC<Props> = ({ folderId, isEditor, canEdit }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const actionUrl = (type: string) => {
    let url = `dashboard/${type}`;

    if (folderId) {
      url += `?folderId=${folderId}`;
    }

    return url;
  };

  return (
    <HorizontalGroup spacing="md" align="center">
      {canEdit && (
        <LinkButton className={styles.manageButton} href={actionUrl('new')}>
          New Dashboard
        </LinkButton>
      )}
      {!folderId && isEditor && (
        <LinkButton className={styles.manageButton} href="dashboards/folder/new">
          New Folder
        </LinkButton>
      )}
      {canEdit && (
        <LinkButton className={styles.manageButton} href={actionUrl('import')}>
          Import
        </LinkButton>
      )}
    </HorizontalGroup>
  );
};
const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    manageButton: css`
      @media only screen and (max-width: 520px) {
        font-size: 9px;
      }
    `,
  };
});

