import React, { FC } from 'react';
import config from 'app/core/config';
import { Icon, IconName } from '@grafana/ui';
import api_config from '../../../../../api_config.json';

export interface FooterLink {
  text: string;
  icon?: string;
  url?: string;
  target?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [
    {
      text: api_config.version,
      icon: '',
      url: '',
      target: '_blank',
    },
    {
      text: 'Support Forum',
      icon: 'comments-alt',
      url: 'https://forum.ifctrl.net',
      target: '_blank',
    },
    {
      text: 'User Guide',
      icon: 'book',
      url: 'https://forum.ifctrl.net/c/portal/getting-started/7',
      target: '_blank',
    },
    {
      text: 'Service Desk',
      icon: 'bug',
      url: 'https://jira.ifctrl.net/servicedesk/customer/portal/4',
      target: '_blank',
    },
  ];
};

export let getVersionLinks = (): FooterLink[] => {
  const { buildInfo /*,licenseInfo*/ } = config;
  const links: FooterLink[] = [];
  // const stateInfo = licenseInfo.stateInfo ? ` (${licenseInfo.stateInfo})` : '';

  // links.push({ text: `${buildInfo.edition}${stateInfo}`, url: licenseInfo.licenseUrl });

  if (buildInfo.hideVersion) {
    return links;
  }

  // links.push({ text: `v${buildInfo.version} (${buildInfo.commit})` });

  // if (buildInfo.hasUpdate) {
  //   links.push({
  //     text: `New version available!`,
  //     icon: 'download-alt',
  //     url: 'https://grafana.com/grafana/download?utm_source=grafana_footer',
  //     target: '_blank',
  //   });
  // }

  return links;
};

export function setFooterLinksFn(fn: typeof getFooterLinks) {
  getFooterLinks = fn;
}

export function setVersionLinkFn(fn: typeof getFooterLinks) {
  getVersionLinks = fn;
}

export const Footer: FC = React.memo(() => {
  const links = getFooterLinks().concat(getVersionLinks());

  return (
    <footer className="footer">
      <div className="text-right">
        <ul>
          {links.map((link) => (
            <li key={link.text}>
              <a href={link.url} target={link.target} rel="noopener" style={{ color: '#FFFFFF' }}>
                {link.icon && <Icon name={link.icon as IconName} />} {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';
