import React from 'react';
import { Button, TextArea, Field, Icon } from '@grafana/ui';
import Select from '../../../../../../packages/grafana-ui/src/components/Forms/Legacy/Select/Select';
import { SelectableValue, AppEvents } from '@grafana/data';
import { Input } from '../../../../../../packages/grafana-ui/src/components/Forms/Legacy/Input/Input';
import { appEvents } from 'app/core/app_events';
import config from './supportform_config.json';
import { DashboardModel } from 'app/features/dashboard/state';
import { contextSrv } from 'app/core/services/context_srv';
import API_URL from '../../../../../../api_config.json';
interface Props {
  dashboard: DashboardModel;
  onDismiss(): void;
}

interface State {
  otherProblemInput: boolean;
  selectedProblem: any;
  problemText: string;
  description: string;
  formError: string;
}

export class SupportModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      otherProblemInput: false,
      selectedProblem: {},
      problemText: '',
      description: '',
      formError: '',
    };
  }

  onDismiss = () => {
    this.props.onDismiss();
  };

  /** On change problem dropdown */
  onSelectProblem = (value: SelectableValue<string>) => {
    this.setState({
      otherProblemInput: value.value === 'other' ? true : false,
      selectedProblem: value,
      problemText: '',
    });
  };

  /** On change problem input test if other is selected */
  onProblemTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ problemText: event.target.value });
  };

  /** On decription text change */
  onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.target.value });
  };

  /** API call on form submit */
  onSubmit = async () => {
    const { selectedProblem, problemText, description } = this.state;
    const problemValue =
      selectedProblem.value !== 'other'
        ? selectedProblem.label
        : problemText === ''
        ? selectedProblem.label
        : problemText;
    const m = new Date();
    const dateString =
      m.getFullYear() +
      '-' +
      ('0' + (m.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + m.getDate()).slice(-2) +
      ' ' +
      ('0' + m.getHours()).slice(-2) +
      ':' +
      ('0' + m.getMinutes()).slice(-2) +
      ':' +
      ('0' + m.getSeconds()).slice(-2);
    const data = {
      problem: problemValue,
      description: description,
      username: contextSrv.user.email,
      email: contextSrv.user.email,
      organization: contextSrv.user.orgName,
      currentURL: window.location.href,
      currentTime: dateString,
      support_email: config.support_email,
      slack_channel: config.slack_channel,
    };
    if (this.validateForm(data)) {
      await fetch(API_URL.user_server + '/customerSupport', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (data) => {
          appEvents.emit(AppEvents.alertSuccess, ['Form submitted successfully !']);
        })
        .catch(function (error) {
          console.log(error);
          appEvents.emit(AppEvents.alertError, ['Something Went Wrong!']);
        });
    }
    this.props.onDismiss();
  };

  validateForm = (data: any) => {
    if (!data.problem) {
      this.setState({
        formError: 'Please select problem !',
      });
      return false;
    } else if (!data.description) {
      this.setState({
        formError: 'Please enter description !',
      });
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div className="support-form-main">
        <div className="support-form">
          <p className="closeIcon" onClick={this.onDismiss}>
            <Icon name="fa fa-close" className="fa-times-circle-o" />
          </p>
          <h3>Customer Support</h3>
          <div className="form-field-inline">
            <Field label="Problem">
              <Select options={config.problems} onChange={this.onSelectProblem} value={this.state.selectedProblem} />
            </Field>
          </div>
          {this.state.otherProblemInput ? (
            <div className="form-field-inline">
              <Field>
                <Input name="problem" type="text" onChange={this.onProblemTextChange} value={this.state.problemText} />
              </Field>
            </div>
          ) : null}
          <div className="formField">
            <div className="form-field-inline">
              <Field label="Description">
                <TextArea
                  style={{ minHeight: '200px' }}
                  onChange={this.onDescriptionChange}
                  value={this.state.description}
                />
              </Field>
            </div>
            <p className="errorMsg">{this.state.formError}</p>
          </div>
          <div className="form-field-inline" style={{ textAlign: 'center' }}>
            <Button id="submit" variant="primary" size="lg" key="lg" onClick={this.onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
