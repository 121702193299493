import React, { FC, SyntheticEvent } from 'react';
import { cx, css, keyframes } from 'emotion';
import { useStyles } from '@grafana/ui';
import { Branding } from '../Branding/Branding';
import { GrafanaTheme } from '@grafana/data';
import { Footer } from '../Footer/Footer';
import { AgreementForm } from './AgreementForm';

interface InnerBoxProps {
  enterAnimation?: boolean;
}
export const InnerBox: FC<InnerBoxProps> = ({ children, enterAnimation = true }) => {
  const loginStyles = useStyles(getLoginStyles);
  return <div className={cx(loginStyles.loginInnerBox, enterAnimation && loginStyles.enterAnimation)}>{children}</div>;
};

/** Props to toggle agreement form show/hide */
interface Props {
  showAgreement?: boolean;
  toggleAgreementModalProp?: (event?: SyntheticEvent) => void;
}

export const LoginLayout: FC<Props> = ({ children, showAgreement, toggleAgreementModalProp }) => {
  const loginStyles = useStyles(getLoginStyles);
  const bg2 = css`
  background: -webkit-linear-gradient(#E3E3E38C,#bfbfbf94));
  background: -moz-linear-gradient(#E3E3E38C,#bfbfbf94));
  background: -o-linear-gradient(#E3E3E38C,#bfbfbf94));
  background: linear-gradient(#E3E3E38C,#bfbfbf94));
  background: linear-gradient(0deg, rgb(207, 216, 220) 0%, rgb(142, 148, 151) 7%, rgba(0, 0, 0, 0) 25%);

  -webkit-transition: background 1s ease-out;
  -moz-transition: background 1s ease-out;
  -o-transition: background 1s ease-out;
  transition: background 1s ease-out;

  position: absolute;
  width: 100%;
  height: 100%;
`;
  return (
    <Branding.LoginBackground className={loginStyles.container}>
      <div className={cx(bg2, 'any')}></div>
      <a
        style={{ height: '50px', position: 'absolute', zIndex: 3, width: '250px', left: '20px', bottom: '15px' }}
        href="https://www.ifctrl.com/"
      >
        <img className={loginStyles.loginIfctrlLogo} src="public/img/ifxctrl_logo.png"></img>
      </a>
      <div className={cx(loginStyles.loginContent, Branding.LoginBoxBackground())}>
        <div className={loginStyles.loginLogoWrapper}>
          <Branding.LoginLogo className={loginStyles.loginLogo} />
          <div className={loginStyles.titleWrapper}>
            <p className={loginStyles.subTitle}>{Branding.GetLoginSubTitle()}</p>
          </div>
        </div>
        <div className={loginStyles.loginOuterBox}>{children}</div>
        <a style={{ textAlign: 'center' }} href="https://www.mozilla.org/en-US/firefox/new/">
          Optimized for{' '}
          <img
            style={{ width: '75px' }}
            src="https://d33wubrfki0l68.cloudfront.net/fabe6afc52da7e7dedf2677dc8a5ad84be94c6da/758ef/images/product-identity-assets/firefox-woodmark-horz.png"
          />
        </a>
      </div>
      <AgreementForm showAgreement={showAgreement} toggleAgreementModalProp={toggleAgreementModalProp} />
      <Footer />
    </Branding.LoginBackground>
  );
};

const flyInAnimation = keyframes`
from{
  opacity: 0;
  transform: translate(-60px, 0px);
}

to{
  opacity: 1;
  transform: translate(0px, 0px);
}`;

export const getLoginStyles = (theme: GrafanaTheme) => {
  const bgColor = theme.isDark ? theme.palette.black : theme.palette.white;
  return {
    container: css`
      min-height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-color: ${bgColor};
      min-width: 100%;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    submitButton: css`
      justify-content: center;
      width: 100%;
    `,
    loginLogo: css`
      width: 100%;
      max-width: 250px;
      margin-bottom: 10px;
    `,
    loginLogoWrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: ${theme.spacing.lg};
    `,
    titleWrapper: css`
      text-align: center;
    `,
    mainTitle: css`
      font-size: 32px;
    `,
    subTitle: css`
      font-size: ${theme.typography.size.md};
      color: ${theme.colors.textSemiWeak};
    `,
    loginContent: css`
      max-width: 550px;
      width: 100%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      position: relative;
      justify-content: center;
      z-index: 1;
      min-height: 320px;
      border-radius: 3px;
      padding: 20px 0;
      @media only screen and (max-width: 1234px){
        height: 561px;
        bottom: 43px;
      }
      @media only screen and (max-width: 1234px){
        padding: 14px 50px;
        max-width : 348px !important;
        height: 537px;
      }

    `,
    loginOuterBox: css`
      display: flex;
      overflow-y: hidden;
      align-items: center;
      justify-content: center;
    `,
    loginInnerBox: css`
      padding: ${theme.spacing.xl};
      @media (max-width: 320px) {
        padding: ${theme.spacing.lg};
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      max-width: 415px;
      width: 100%;
      transform: translate(0px, 0px);
      transition: 0.25s ease;
    `,
    enterAnimation: css`
      animation: ${flyInAnimation} ease-out 0.2s;
    `,
    loginIfctrlLogo: css`
      width: 250px;
      position: absolute;
      left: 20px;
      bottom: 15px;
      @media only screen and (max-width: 700px) and (min-width:600px) {
        left: 29vw;
        bottom: 28px;
  
      }
      @media only screen and (max-width: 600px){
        left: 16vw;
        bottom: 40px;
      }

    `,
  };
};
