import React, { FC, useEffect, useState } from 'react';
import { css, cx } from 'emotion';
import { useTheme } from '@grafana/ui';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return (
    <a href="https://www.ifctrl.com/">
      <img className={className} src="public/img/apricot_ifctrl_logo.png" alt="APRICOT Connect" />
    </a>
  );
};

/** Initial url of background image */
let backgroundImg = {
  url: '',
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    if (backgroundImg.url === '') {
      loadImages();
    } else {
      setImgUrl(backgroundImg.url);
    }
  }, []);

  /** Function to set random image url to the background */
  const loadImages = () => {
    const imageList: any = [
      'public/img/wtp1.png',
      'public/img/home_background/wtrp2.jpg',
      'public/img/home_background/wtrp4.jpg',
      'public/img/home_background/wtrp5.jpg',
      'public/img/home_background/wtrp6.jpg',
      'public/img/home_background/wtrp7.jpg',
    ];
    const num = Math.floor(Math.random() * imageList.length);
    backgroundImg.url = imageList[num];
    setImgUrl(imageList[num]);
  };

  // const theme = useTheme();
  // const background = css`
  //   background: url(public/img/login_background_${theme.isDark ? 'dark' : 'light'}.svg);
  //   background-size: cover;
  // `;
  const background = css`
    background: url(${imgUrl});
    background-size: cover;
  `;
  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/ifctrl_tree.png" alt="APRICOT" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme();
  return css`
    background: ${theme.isLight ? 'rgba(6, 30, 200, 0.1 )' : 'rgba(18, 28, 41, 0.65)'};
    background-size: cover;
    border-width: 2px;
    border-color: black;
    box-shadow: 0px 1px 1px 1px #d2d2d2;
    padding: 100px 50px;
    max-width: 400px !important;
    background-color: #ffffffe3;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'APRICOT Portal';
  static LoginTitle = '';
  static GetLoginSubTitle = () => {
    const slogans = ['In control. Anywhere. Anytime.'];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
