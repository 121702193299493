/**
 *  THIS FILE HAS BEEN MODIFIED BY INTELLIFLUX CONTROLS
 */

import { getDatasourceSrv } from 'app/features/plugins/datasource_srv';
// import { getBackendSrv } from 'app/core/services/backend_srv';
// import store from 'app/core/store';
import { SetupStep } from './types';

// const step1TutorialTitle = 'Explore your data';
// const step2TutorialTitle = 'Next Best Action';
// const keyPrefix = 'getting.started.';
// const step1Key = `${keyPrefix}${step1TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;
// const step2Key = `${keyPrefix}${step2TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;

export const getSteps = (): SetupStep[] => [
  {
    heading: 'Welcome to APRICOT',
    subheading: 'Automated Process Recommendation & Industrial Control Optimization Tools',
    title: 'QUICKSTART',
    info: 'Follow these steps to connect your data for use with our data analysis and optimization toolkits.',
    done: false,
    cards: [
      {
        type: 'docs',
        heading: 'EASY - START HERE',
        title: 'Upload a .csv file (24MB)',
        info: 'Upload CSV Data (24MB)',
        icon: 'apps',
        href: 'upload',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-connecting-your-data-upload-csv/60',
        check: async () => {
          // const result = await getBackendSrv().search({ limit: 1 });
          //return result.length > 0;
          return false;
        },
        done: false,
      },
      {
        type: 'docs',
        title: 'Connect a SQL database',
        heading: 'INTERMEDIATE',
        info: 'Connect a SQL database',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-connecting-your-data-sql-databases/42',
        href: 'datasources/new/sql',
        check: () => {
          return new Promise((resolve) => {
            resolve(
              getDatasourceSrv()
                .getMetricSources()
                .filter((item) => {
                  return false;
                  return item.meta.builtIn !== true;
                }).length > 0
            );
          });
        },
        done: false,
      },
      {
        type: 'link',
        title: 'What else can I do?',
        heading: 'Community Forum',
        info: 'GET HELP',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/getting-started-with-apricot-portal/54',
        href: 'https://forum.ifctrl.net/t/getting-started-with-apricot-portal/54',
        check: () => {
          return new Promise((resolve) => {
            resolve(
              getDatasourceSrv()
                .getMetricSources()
                .filter((item) => {
                  return false;
                  return item.meta.builtIn !== true;
                }).length > 0
            );
          });
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Welcome to APRICOT',
    subheading: 'Automated Process Recommendation & Industrial Control Optimization Tools',
    title: 'Build Your Dashboard',
    info: 'Follow these steps to customize your dashboard and dive into your data.',
    done: false,
    cards: [
      {
        type: 'docs',
        heading: 'ASSIST',
        title: 'Add a dashboard',
        info: 'Upload CSV Data',
        icon: 'apps',
        href: '/dashboard/new',
        learnHref: 'https://forum.ifctrl.net/t/getting-started-with-apricot-portal/54',
        check: async () => {
          // const result = await getBackendSrv().search({ limit: 1 });
          //return result.length > 0;
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Create Time Series Plot',
        heading: 'Create Time Series Plot',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-time-series/44',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-time-series/44',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Create Phase Plot',
        heading: 'Create Phase Plot',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-scatter-plot/45',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-scatter-plot/45',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Simple Time Aggregations',
        heading: 'Simple Time Aggregations',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-aggregations/46',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-aggregations/46',
        check: async () => {
          return false;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Welcome to APRICOT',
    subheading: 'Automated Process Recommendation & Industrial Control Optimization Tools',
    title: 'Manage Your Data',
    info: 'Track KPI excursions, get notifications, and generate reports from your data.',
    done: false,
    cards: [
      {
        type: 'link',
        title: 'Add Annotations',
        heading: 'Add Annotations',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-annotations/47',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-annotations/47',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Create Notifications',
        heading: 'Create Notifications',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-notifications/48',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-notifications/48',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Generate a PDF Report',
        heading: 'Generate a PDF Report',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-virtual-reporter/55',
        href: 'https://forum.ifctrl.net/t/advanced-portal-virtual-reporter/55',
        check: async () => {
          return false;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Welcome to APRICOT',
    subheading: 'Automated Process Recommendation & Industrial Control Optimization Tools',
    title: 'Advanced Algorithms',
    info: 'Follow these steps to apply advanced algorithms for comprehensive data analysis.',
    done: false,
    cards: [
      {
        type: 'link',
        title: 'Clustering',
        heading: 'Clustering',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-clustering/51',
        href: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-clustering/51',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Regression',
        heading: 'Regression',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-regression/52',
        href: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-regression/52',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Dimensionality Reduction',
        heading: 'Dimensionality Reduction',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-reduction/53',
        href: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-reduction/53',
        check: async () => {
          return false;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Setup complete!',
    subheading:
      'All necessary steps to use Grafana are done. Now tackle advanced steps or make the best use of this home dashboard – it is, after all, a fully customizable dashboard – and remove this panel.',
    title: 'OPERATE',
    info: 'Contact us to find out what its like to have an AI autopilot for your plant',
    done: true,
    cards: [
      {
        type: 'link',
        heading: 'APRICOT OPERATE',
        title: 'Add an AI autopilot to your plant',
        info: 'Contact Us',
        icon: 'apps',
        href: 'https://www.ifctrl.com/contact',
        learnHref: 'https://www.ifctrl.com/contact',
        check: async () => {
          // const result = await getBackendSrv().search({ limit: 1 });
          //return result.length > 0;
          return false;
        },
        done: false,
      },
    ],
  },
];
