import React from 'react';
import { SelectableValue, standardTransformers, TransformerRegistryItem, TransformerUIProps } from '@grafana/data';
import { Input, Button, IconButton, Select } from '@grafana/ui';
import { AnalyticsTransformerOptions } from '@grafana/data/src/transformations/transformers/analytics';
import { contextSrv } from 'app/core/services/context_srv';
import configData from '../../../../../api_config.json';

interface AnalyticsTransformerEditorProps extends TransformerUIProps<AnalyticsTransformerOptions> {}

interface AnalyticsTransformerEditorState {
  server: string;
  _package: string;
  _package_desc: string;

  _module: string;
  _module_desc: string;

  _function: string;
  _function_desc: string;

  table: [];

  params: { [key: string]: any };
  packages: any[];
  modules: any[];
  functions: any[];
  init: boolean;
  applyButtonHit: boolean;
  newOptions: {}; //state to set options on change
}

export class AnalyticsTransformerEditor extends React.PureComponent<
  AnalyticsTransformerEditorProps,
  AnalyticsTransformerEditorState
> {
  private serverField = React.createRef<HTMLInputElement>();
  private serverFieldButton = React.createRef<HTMLButtonElement>();
  constructor(props: AnalyticsTransformerEditorProps) {
    super(props);

    if (this.props.options.init) {
      // console.log(this.props);
      this.state = {
        server: configData.analytics_server,
        _package: this.props.options._package,
        _package_desc: this.props.options._package_desc,

        _module: this.props.options._module,
        _module_desc: this.props.options._module_desc,

        _function: this.props.options._function,
        _function_desc: this.props.options._function_desc,

        table: this.props.options.table,

        params: this.props.options.params,
        packages: this.props.options.packages,
        modules: this.props.options.modules,
        functions: this.props.options.functions,
        init: this.props.options.init,
        applyButtonHit: this.props.options.applyButtonHit,
        newOptions: {},
      };
    } else {
      this.state = {
        server: configData.analytics_server,
        //server: 'http://localhost:5050/',
        _package: 'analysis',
        _package_desc: 'This package provides insight on relationships within your data.',
        _module_desc: 'Explore the relationships present in the data',
        _module: 'components',
        _function: 'pca',
        _function_desc: 'Project the data into the Principal Component space.',
        table: [],
        params: { variance_retention: 0.99, mode: 'projection' },
        packages: [
          { label: 'analysis', value: 'analysis' },
          { label: 'classification', value: 'classification' },
          { label: 'preprocessing', value: 'preprocessing' },
          { label: 'regression', value: 'regression' },
        ],
        modules: [
          { label: 'components', value: 'components' },
          { label: 'statistics', value: 'statistics' },
          { label: 'time', value: 'time' },
        ],
        functions: [
          { label: 'pca', value: 'pca' },
          { label: 'factor_analysis', value: 'factor_analysis' },
          { label: 'fast_ica', value: 'fast_ica' },
          { label: 'incremental_pca', value: 'incremental_pca' },
          { label: 'sparse_pca', value: 'sparse_pca' },
        ],
        init: false,
        applyButtonHit: false,
        newOptions: {},
      };
      this.getPackages();
    }
  }

  componentDidMount = async () => {
    const { onChange, options } = this.props;
    onChange({ ...options, server: configData.analytics_server });
  };

  getPackages = () => {
    const { options } = this.props;
    const url: string = options.server + '/describe/packages';

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let opts = data.map((key: any) => {
          return { label: key, value: key };
        });

        this.setState({
          _package: opts[0].label,
          packages: opts,
          init: true,
        });

        this.setState((prevState) => ({
          newOptions: {
            ...prevState.newOptions,
            _package: opts[0].label,
            packages: opts,
            init: true,
          },
        }));
        // onChange({ ...options, _package: opts[0].label, packages: opts, init: true });
        this.onPackageChange(opts[0]);
      });
  };

  // getParams = () => {
  //   const options: AnalyticsTransformerOptions = { ...this.props.options };
  //   const url: string =
  //     options.server + '/describe/' + options._package + '/' + options._module + '/' + options._function;

  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       onChange({ ...query, params: data });
  //     });
  // };

  onPackageChange = (v: SelectableValue<string>) => {
    const { options } = this.props;
    const url: string = options.server + '/describe/' + v.value;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let opts = data.modules.map((key: any) => {
          return { label: key, value: key };
        });

        this.setState({
          _package: v.value || '',
          modules: opts,
          _module: opts[0].label,
          _function: '',
          params: { 'N/A': '' },
          init: true,
          _package_desc: data.description,
        });

        this.setState((prevState) => ({
          newOptions: {
            ...prevState.newOptions,
            _package: v.value,
            modules: opts,
            _module: opts[0].label,
            _function: '',
            params: { 'N/A': '' },
            init: true,
            _package_desc: data.description,
          },
        }));

        // onChange({ ...options, _package: v.value, modules: opts, _module: opts[0].label, _function: '', params: { 'N/A': '' }, init: true, _package_desc: data.description });
        this.onModuleChange(opts[0]);
      });
  };

  onModuleChange = (v: SelectableValue<string>) => {
    const { options } = this.props;
    const url: string = options.server + '/describe/' + this.state._package + '/' + v.value;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let opts = data.functions.map((key: any) => {
          return { label: key, value: key };
        });

        this.setState({
          _module: v.value || '',
          functions: opts,
          _function: opts[0].label,
          params: { 'N/A': '' },
          init: true,
          _module_desc: data.description,
        });

        this.setState((prevState) => ({
          newOptions: {
            ...prevState.newOptions,
            _module: v.value,
            functions: opts,
            _function: opts[0].label,
            params: { 'N/A': '' },
            init: true,
            _module_desc: data.description,
          },
        }));
        // onChange({ ...options, _module: v.value, functions: opts, _function: opts[0].label, params: { 'N/A': '' }, init: true, _module_desc: data.description });
        this.onFunctionChange(opts[0]);
      });
  };

  onFunctionChange = (v: SelectableValue<string>) => {
    const { onChange, options } = this.props;
    const url: string = options.server + '/describe/' + this.state._package + '/' + this.state._module + '/' + v.value;

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        let data = json.params;
        let _params: any = {};

        // console.log(json);

        // console.log("!!!TABLE!!!")
        // console.log(table)
        for (let i = 0; i < data.length; i++) {
          _params[data[i].label] = data[i].value;

          // console.log(data[i].control_spec.values);

          //           switch (data[i].control_type) {
          //             case "slider":
          //               control = <div style={{width:'160px'}}><Slider min={data[i].control_spec.min} max={data[i].control_spec.max} onChange={e => this.onParamChange(data[i].label, e)}/></div>;
          //               break;
          //             case "dropdown":
          //               let opts = data[i].control_spec.values.map(key => {
          //                 return { label: key, value: key };
          //               });
          //               control = <div style={{width:'160px'}}><Select value={options.params[data[i].label]} options={opts} onChange={e => this.onParamChange(data[i].label, e)}  /></div>;
          //               break;
          //             case "string_textbox":
          //               control = <Input width={20} type={'text'} value={options.params[data[i].label]} onChange={e => this.onParamChange(data[i].label, e)}/>;
          //               break;
          //             case "numeric_textbox":
          //               control = <Input width={20} type={'number'} value={options.params[data[i].label]} onChange={e => this.onParamChange(data[i].label, e)}/>;
          //               break;
          //             default:
          //               break;
          //           }

          //           table.push({
          //             param: data[i].label,
          //             description: data[i].description,
          //             control: control,
          //           });
        }

        this.setState({
          _function: v.value || '',
          params: _params,
          init: true,
          table: data,
          _function_desc: json.description,
        });
        this.setState((prevState) => ({
          newOptions: {
            ...prevState.newOptions,
            _function: v.value,
            params: _params,
            init: true,
            table: data,
            _function_desc: json.description,
          },
        }));
        // onChange({ ...options, _function: v.value, params: _params, init: true, table: data, _function_desc: json.description });

        /** Call onChange for first time on load */
        if (!this.props.options.init) {
          onChange({ ...options, ...this.state.newOptions });
        }
      });
  };

  renderSwitch = (val: any) => {
    // const { options } = this.props;
    switch (val.control_type) {
      case 'slider':
        return (
          <Input
            width={20}
            type={'number'}
            value={this.state.params[val.label]}
            onChange={(e) => this.onParamChange(val.label, e)}
          />
        );
      //return <div style={{width:'160px'}}><Slider value={options.params[val.label]} min={val.control_spec.min} max={val.control_spec.max} onChange={e => this.onParamChange(val.label, e)}/></div>;
      case 'dropdown':
        let opts = val.control_spec.values.map((key: any) => {
          return { label: key, value: key };
        });
        return (
          <div style={{ width: '160px' }}>
            <Select
              value={this.state.params[val.label]}
              options={opts}
              onChange={(e) => this.onParamChange(val.label, e)}
            />
          </div>
        );
      case 'string_textbox':
        return (
          <Input
            width={20}
            type={'text'}
            value={this.state.params[val.label]}
            onChange={(e) => this.onParamChange(val.label, e)}
          />
        );
      case 'numeric_textbox':
        return (
          <Input
            width={20}
            type={'number'}
            value={this.state.params[val.label]}
            min="{val.control_spec.min}"
            max="{val.control_spec.max}"
            onChange={(e) => this.onParamChange(val.label, e)}
          />
        );
      default:
        return (
          <Input
            width={20}
            type={'text'}
            value={this.state.params[val.label]}
            onChange={(e) => this.onParamChange(val.label, e)}
          />
        );
      // break;
    }
  };

  onServerChange = () => {
    const { onChange, options } = this.props;
    // var server_field = document.getElementById("server_field");

    const server_field = this.serverField.current;
    // console.log("pressed button");
    onChange({ ...options, server: server_field!.value });
  };

  toggleServerField = () => {
    // var x = document.getElementById("server_field");
    // var y = document.getElementById("server_field_button");

    const x = this.serverField.current;
    const y = this.serverFieldButton.current;

    if (x?.style.display === 'none') {
      x.style.display = 'inline-block';
    } else {
      x!.style.display = 'none';
    }

    if (y?.style.display === 'none') {
      y.style.display = 'inline-block';
    } else {
      y!.style.display = 'none';
    }
  };

  onParamChange = (key: string, event: any) => {
    // let { params, table } = options;
    let params: any = {};
    if ('label' in event) {
      params[key] = event.value;
    } else {
      params[key] = event.target.value;
    }

    this.setState(
      (prevState) => ({
        params: {
          ...prevState.params,
          ...params,
        },
      }),
      () =>
        this.setState((prevState: any) => ({
          newOptions: {
            ...prevState.newOptions,
            params: { ...prevState.newOptions.params, ...this.state.params },
            init: true,
          },
        }))
    );
    // onChange({ ...options, params: params, init: true });
  };

  /** Apply transformation on Apply button click */
  applyTransformation = () => {
    const { onChange, options } = this.props;
    var temp = {...this.state.newOptions, applyButtonHit: true};
    onChange({ ...options, ...temp});

  };

  render() {
    // const { server, _package, _package_desc, _module, _module_desc, params, _function, _function_desc, packages, modules, functions, table, } = this.props.options;
    // const { server } = this.props.options;

    //     const table = [
    //       {
    //         param : 'Param A',
    //         description : 'The description of the package will be populated in this area',
    //         control: <Input
    //           width={20}
    //           type={'number'}
    //         />
    //       },
    //       {
    //         param : 'Param B',
    //         description : 'The description of the package will be populated in this area',
    //         control: <div style={{width:'160px'}}><Select value={_package} label="Package" options={packages} onChange={this.onPackageChange}  /></div>
    //       },
    //       {
    //         param : 'Param C',
    //         description : 'The description of the package will be populated in this area',
    //         control: <div style={{width:'160px'}}><Slider min={10} max={100}/></div>
    //       }
    //     ];
    // <FormField width={4} value={server} label="API Server" type="string" onChange={this.onServerChange} />
    return (
      //   <div className="gf-form-inline">
      //   <div className="gf-form gf-form--grow">
      //     <div className="api-setup-info">

      //       <Field label="Package">
      //         <Select value={_package} label="Package" options={packages} onChange={this.onPackageChange} />
      //       </Field>

      //       <Field label="Module">
      //         <Select value={_module} label="Module" options={modules} onChange={this.onModuleChange} />
      //       </Field>

      //       <Field label="Function">
      //         <Select value={_function} label="Function" options={functions} onChange={this.onFunctionChange} />
      //       </Field>
      //     </div>

      //     <div style={{ padding: "15px" }}>
      //       {Object.keys(params).map(function(key, idx) {
      //         return (
      //           <FormField
      //             width={2}
      //             value={params[key]}
      //             name={key}
      //             key={key}
      //             label={key}
      //             type="string"
      //             onChange={e => this.onParamChange(key, e)}
      //           />
      //         );
      //       }, this)}
      //     </div>
      //   </div>
      // </div>
      <div>
        <div className="gf-form-inline" style={{ background: '#f1f5f9' }}>
          <div
            id="assist-analytics-header"
            style={{
              width: '100%',
              borderBottom: '#dee4ea',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              marginBottom: '10px',
            }}
          >
            <img style={{ maxWidth: '250px' }} src="public/img/logo_assist.png" />
            <span style={{ fontSize: '18px', verticalAlign: 'middle' }}>Analytics Module</span>
            {contextSrv.user.orgName === 'IFCTRL Internal' && (
              <div style={{ float: 'right', paddingTop: '15px' }}>
                <input
                  style={{ display: 'none', verticalAlign: 'middle', marginRight: '10px' }}
                  ref={this.serverField}
                  id="server_field"
                  type="text"
                />
                <Button
                  style={{ display: 'none', verticalAlign: 'middle', marginRight: '10px' }}
                  ref={this.serverFieldButton}
                  id="server_field_button"
                  onClick={this.onServerChange}
                >
                  {' '}
                  Apply{' '}
                </Button>
                <IconButton
                  style={{ display: 'inline-block', verticalAlign: 'middle' }}
                  name="cog"
                  size="md"
                  tooltip="Open Server Field"
                  onClick={this.toggleServerField}
                />
              </div>
            )}
          </div>
          <div className="gf-form gf-form--grow">
            <ul style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start', width: '100%' }}>
              <li style={{ width: '33%', display: 'flex', flexFlow: 'column nowrap', padding: '0px 5px' }}>
                <h6>Package</h6>
                <span style={{ marginBottom: '5px' }}>{this.state._package_desc}</span>
                <div style={{ marginTop: 'auto' }}>
                  <Select
                    value={this.state._package}
                    // label="Package"
                    options={this.state.packages}
                    onChange={this.onPackageChange}
                  />
                </div>
              </li>

              <li style={{ width: '33%', display: 'flex', flexFlow: 'column nowrap', padding: '0px 5px' }}>
                <h6>Module</h6>
                <span style={{ marginBottom: '5px' }}>{this.state._module_desc}</span>
                <div style={{ marginTop: 'auto' }}>
                  <Select
                    value={this.state._module}
                    // label="Module"
                    options={this.state.modules}
                    onChange={this.onModuleChange}
                  />
                </div>
              </li>

              <li style={{ width: '33%', display: 'flex', flexFlow: 'column nowrap', padding: '0px 5px' }}>
                <h6>Function</h6>
                <span style={{ marginBottom: '5px' }}>{this.state._function_desc}</span>
                <div style={{ marginTop: 'auto' }}>
                  {/*console.log(this.state._function)*/}
                  <Select
                    value={this.state._function}
                    // label="Function"
                    options={this.state.functions}
                    onChange={this.onFunctionChange}
                  />
                </div>
              </li>
            </ul>

            {/*
          <div style={{ padding: "15px" }}>
            {Object.keys(params).map(function(key, idx) {
              return (
                <FormField
                  width={2}
                  value={params[key]}
                  name={key}
                  key={key}
                  label={key}
                  type="string"
                  onChange={e => this.onParamChange(key, e)}
                />
              );
            }, this)}
          </div> */}
          </div>
        </div>
        <div style={{ width: '100%', maxWidth: '100%' }}>
          <table style={{ width: '100%', maxWidth: '100%' }}>
            <thead>
              <tr>
                <th style={{ padding: '10px', fontWeight: 'bold' }}>Parameter</th>
                <th style={{ padding: '10px', fontWeight: 'bold' }}>Description</th>
                <th style={{ padding: '10px', fontWeight: 'bold' }}>Control</th>
              </tr>
            </thead>
            <tbody>
              {this.state.table &&
                this.state.table.map((val: any, idx: number) => {
                  return (
                    <tr key={idx}>
                      <td style={{ padding: '10px' }}>{val.label}</td>
                      <td style={{ padding: '10px' }}>{val.description}</td>
                      <td style={{ padding: '10px' }}>{this.renderSwitch(val)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {/* <Table data={table} height={200} width={1000} /> */}
        </div>
        <div className="form-field-inline">
          <Button
            id="apply_transformation_btn"
            variant="primary"
            size="md"
            key="md"
            style={{ float: 'right' }}
            onClick={this.applyTransformation}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  }
}

export const analyticsTransformerRegistryItem: TransformerRegistryItem<AnalyticsTransformerOptions> = {
  id: 'ifanalytics',
  editor: AnalyticsTransformerEditor,
  transformation: standardTransformers.analyticsTransformer,
  name: 'APRICOT ASSIST Analytics',
  description: 'Explore your data in depth with data analysis and simple ML algorithms.',
  logoUrl: 'public/img/ifctrl_tree.png',
};
