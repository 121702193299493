/**
 *  THIS FILE HAS BEEN MODIFIED BY INTELLIFLUX CONTROLS
 */

// import { getBackendSrv } from 'app/core/services/backend_srv';
import { SetupStep } from './types';

// const step1TutorialTitle = 'Explore your data';
// const step2TutorialTitle = 'Next Best Action';
// const keyPrefix = 'getting.started.';
// const step1Key = `${keyPrefix}${step1TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;
// const step2Key = `${keyPrefix}${step2TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;

export const getSteps = (): SetupStep[] => [
  {
    heading: 'Welcome to APRICOT',
    subheading: 'Automated Process Recommendation & Industrial Control Optimization Tools',
    title: 'Nice Start!',
    info:
      'Pretty easy right? Try generating a report and editing your dashboard. Here are some resources to help you get started',
    done: false,
    cards: [
      {
        type: 'link',
        title: 'Download your Dashboard',
        heading: 'Generate PDF Report',
        info: '',
        icon: 'download-alt',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-download-pdf-reports/62',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-download-pdf-reports/62',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Create Time Series Plot',
        heading: 'Create Time Series Plot',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-time-series/44',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-time-series/44',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Create Phase Plot',
        heading: 'Create Phase Plot',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-scatter-plot/45',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-scatter-plot/45',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Simple Time Aggregations',
        heading: 'Simple Time Aggregations',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-aggregations/46',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-time-traces-aggregations/46',
        check: async () => {
          return false;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Welcome to APRICOT',
    subheading: 'Automated Process Recommendation & Industrial Control Optimization Tools',
    title: 'Manage Your Data',
    info: 'Track KPI excursions, get notifications, and generate reports from your data.',
    done: false,
    cards: [
      {
        type: 'link',
        title: 'Add Annotations',
        heading: 'Add Annotations',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-annotations/47',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-annotations/47',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Create Notifications',
        heading: 'Create Notifications',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-notifications/48',
        href: 'https://forum.ifctrl.net/t/intro-to-portal-creating-alerts-notifications/48',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Generate a PDF Report',
        heading: 'Generate a PDF Report',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-virtual-reporter/55',
        href: 'https://forum.ifctrl.net/t/advanced-portal-virtual-reporter/55',
        check: async () => {
          return false;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Welcome to APRICOT',
    subheading: 'Automated Process Recommendation & Industrial Control Optimization Tools',
    title: 'Advanced Algorithms',
    info: 'Follow these steps to apply advanced algorithms for comprehensive data analysis.',
    done: false,
    cards: [
      {
        type: 'link',
        title: 'Clustering',
        heading: 'Clustering',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-clustering/51',
        href: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-clustering/51',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Regression',
        heading: 'Regression',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-regression/52',
        href: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-regression/52',
        check: async () => {
          return false;
        },
        done: false,
      },
      {
        type: 'link',
        title: 'Dimensionality Reduction',
        heading: 'Dimensionality Reduction',
        info: '',
        icon: 'apps',
        learnHref: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-reduction/53',
        href: 'https://forum.ifctrl.net/t/advanced-portal-applying-data-transforms-reduction/53',
        check: async () => {
          return false;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Setup complete!',
    subheading:
      'All necessary steps to use Grafana are done. Now tackle advanced steps or make the best use of this home dashboard – it is, after all, a fully customizable dashboard – and remove this panel.',
    title: 'OPERATE',
    info: 'Contact us to find out what its like to have an AI autopilot for your plant',
    done: true,
    cards: [
      {
        type: 'link',
        heading: 'APRICOT OPERATE',
        title: 'Add an AI autopilot to your plant',
        info: 'Contact Us',
        icon: 'apps',
        href: 'https://www.ifctrl.com/contact',
        learnHref: 'https://www.ifctrl.com/contact',
        check: async () => {
          // const result = await getBackendSrv().search({ limit: 1 });
          //return result.length > 0;
          return false;
        },
        done: false,
      },
    ],
  },
];
