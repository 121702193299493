// Libaries
import React, { PureComponent, FC, ReactNode } from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
// Utils & Services
import { appEvents } from 'app/core/app_events';
import { AppEvents, textUtil } from '@grafana/data';
import { PlaylistSrv } from 'app/features/playlist/playlist_srv';
// Components
import { DashNavButton } from './DashNavButton';
import { DashNavTimeControls } from './DashNavTimeControls';
import { ButtonGroup, ModalsController, ToolbarButton, PageToolbar, Icon, Button } from '@grafana/ui';
// State
import { updateLocation } from 'app/core/actions';
import { updateTimeZoneForSession } from 'app/features/profile/state/reducers';
// Types
import { DashboardModel } from '../../state';
import { CoreEvents, StoreState } from 'app/types';
import { ShareModal } from 'app/features/dashboard/components/ShareModal';
import { SaveDashboardModalProxy } from 'app/features/dashboard/components/SaveDashboard/SaveDashboardModalProxy';
import { SupportModal } from '../SupportForm';
import { contextSrv } from 'app/core/services/context_srv';
import API_URL from '../../../../../../api_config.json';
import '../style.css';

export interface OwnProps {
  dashboard: DashboardModel;
  isFullscreen: boolean;
  $injector: any;
  onAddPanel: () => void;
}

interface DispatchProps {
  updateTimeZoneForSession: typeof updateTimeZoneForSession;
  updateLocation: typeof updateLocation;
}

interface DashNavButtonModel {
  show: (props: Props) => boolean;
  component: FC<Partial<Props>>;
  index?: number | 'end';
}

const customLeftActions: DashNavButtonModel[] = [];
const customRightActions: DashNavButtonModel[] = [];

export function addCustomLeftAction(content: DashNavButtonModel) {
  customLeftActions.push(content);
}

export function addCustomRightAction(content: DashNavButtonModel) {
  customRightActions.push(content);
}

export interface StateProps {
  location: any;
}

export interface State {
  blockDisplay: string;
  tireValue: string;
  licenseLimit: string;
  name: string;
  email: string;
  phone: string;
  companyName: string;
  industry: string;
  formError: string;
}

type Props = StateProps & OwnProps & DispatchProps;

class DashNav extends PureComponent<Props, State> {
  playlistSrv: PlaylistSrv;

  constructor(props: Props) {
    super(props);
    this.playlistSrv = this.props.$injector.get('playlistSrv');
    this.state = {
      blockDisplay: 'none',
      tireValue: 'free',
      licenseLimit: '15',
      name: '',
      email: '',
      phone: '',
      companyName: '',
      industry: '',
      formError: '',
    };
  }

  onFolderNameClick = () => {
    this.props.updateLocation({
      query: { search: 'open', folder: 'current' },
      partial: true,
    });
  };

  onClose = () => {
    this.props.updateLocation({
      query: { viewPanel: null },
      partial: true,
    });
  };

  onToggleTVMode = () => {
    appEvents.emit(CoreEvents.toggleKioskMode);
  };

  onOpenSettings = () => {
    this.props.updateLocation({
      query: { editview: 'settings' },
      partial: true,
    });
  };

  onStarDashboard = () => {
    const { dashboard, $injector } = this.props;
    const dashboardSrv = $injector.get('dashboardSrv');

    dashboardSrv.starDashboard(dashboard.id, dashboard.meta.isStarred).then((newState: any) => {
      dashboard.meta.isStarred = newState;
      this.forceUpdate();
    });
  };

  onPlaylistPrev = () => {
    this.playlistSrv.prev();
  };

  onPlaylistNext = () => {
    this.playlistSrv.next();
  };

  onPlaylistStop = () => {
    this.playlistSrv.stop();
    this.forceUpdate();
  };

  onDashboardNameClick = () => {
    this.props.updateLocation({
      query: { search: 'open' },
      partial: true,
    });
  };

  addCustomContent(actions: DashNavButtonModel[], buttons: ReactNode[]) {
    actions.map((action, index) => {
      const Component = action.component;
      const element = <Component {...this.props} key={`button-custom-${index}`} />;
      typeof action.index === 'number' ? buttons.splice(action.index, 0, element) : buttons.push(element);
    });
  }

  isInKioskMode() {
    return !!this.props.location.query.kiosk;
  }

  isPlaylistRunning() {
    return this.playlistSrv.isPlaying;
  }

  renderLeftActionsButton() {
    const { dashboard } = this.props;
    const { canStar, canShare, isStarred } = dashboard.meta;
    const buttons: ReactNode[] = [];

    if (this.isInKioskMode() || this.isPlaylistRunning()) {
      return [];
    }

    if (canStar) {
      buttons.push(
        <DashNavButton
          tooltip="Mark as favorite"
          icon={isStarred ? 'favorite' : 'star'}
          iconType={isStarred ? 'mono' : 'default'}
          iconSize="lg"
          onClick={this.onStarDashboard}
          key="button-star"
        />
      );
    }

    if (canShare) {
      buttons.push(
        <ModalsController key="button-share">
          {({ showModal, hideModal }) => (
            <DashNavButton
              tooltip="Share dashboard or panel"
              icon="share-alt"
              iconSize="lg"
              onClick={() => {
                showModal(ShareModal, {
                  dashboard,
                  onDismiss: hideModal,
                });
              }}
            />
          )}
        </ModalsController>
      );
    }

    this.addCustomContent(customLeftActions, buttons);
    return buttons;
  }

  renderPlaylistControls() {
    return (
      <ButtonGroup key="playlist-buttons">
        <ToolbarButton tooltip="Go to previous dashboard" icon="backward" onClick={this.onPlaylistPrev} narrow />
        <ToolbarButton onClick={this.onPlaylistStop}>Stop playlist</ToolbarButton>
        <ToolbarButton tooltip="Go to next dashboard" icon="forward" onClick={this.onPlaylistNext} narrow />
      </ButtonGroup>
    );
  }

  getUrlParams = () => {
    appEvents.emit(AppEvents.alertSuccess, ['Contact us today about unlimited pdf reports from your dashboards!']);
    this.setState({
      blockDisplay: 'block',
    });
    /*
    const { dashboard } = this.props;
    const reportUrl = "https://api.ifctrl.net/vrep/generic_report/" + dashboard.uid + "/" + contextSrv.user.orgId + "?from=" + dashboard.time.from + "&to=" + dashboard.time.to + "&orgName=" + contextSrv.user.orgName


    let paramStr = "";
    for (const param of dashboard.getVariables()) {
      paramStr += paramStr + '&var-' + param.name + '=' + param.current.value;
    }
    console.log(reportUrl + paramStr);
    window.open(reportUrl + paramStr, "_blank");

    return paramStr;
    */
  };

  cloaseForm = () => {
    this.setState({
      blockDisplay: 'none',
    });
  };

  generateReport = async () => {
    this.setState({
      licenseLimit: '15',
      name: '',
      email: '',
      phone: '',
      companyName: '',
      industry: '',
      formError: '',
    });


    var panels = this.props.dashboard.panels
      .map((panel) => {
        if (panel.type === 'natel-plotly-panel' || panel.type === 'neocat-cal-heatmap-panel') {
          return appEvents.emit(AppEvents.alertWarning, [
            'Panel type cannot be rendered in report and will be omitted: ' + panel.type,
          ]);
          // return;
        } else if (panel.type !== 'dashboardstarting') {
          return panel;
        }
      })
      .filter((x) => x !== undefined);

    const orgName_orgID = contextSrv.user.orgName + '_' + contextSrv.user.orgId;
    const url = API_URL.user_server + '/getLicenses/' + orgName_orgID + '/api_token';
    await fetch(url)
      .then((response) => response.json())
      .then(async (data) => {
        if (data && data.status === 'success') {
          let token = '';
          let serviceID = data.license.serviceID;
          let licenseLimit = data.license.limit;
          let bodydata = {
            oid: orgName_orgID,
            serviceID: serviceID,
          };
          //API call to get auth token
          await fetch(API_URL.auth_server + '/getToken', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodydata),
          })
            .then((response) => response.json())
            .then(async (data) => {
              if (data && data.access_token) {
                token = data.access_token;

                console.log('get uses monthly');
                console.log(API_URL.auth_server + '/getUses/monthly');
                //API call to check monthly license limit
                await fetch(API_URL.auth_server + '/getUses/monthly', {
                  method: 'GET',
                  headers: { Token: token },
                })
                  .then((response) => response.json())
                  .then(async (data) => {
                    console.log('Checking licenseLimit');
                    console.log(data);
                    if (data && 'Count' in data) {
                      console.log(data.Count);
                      console.log(licenseLimit);
                      if (licenseLimit === 'unlimited' || data.Count < licenseLimit) {
                        console.log('Calling generic report');

                        var print_limit = 10;

                        if (licenseLimit === 'unlimited') {
                          print_limit = 50;
                        }

                        if (panels.length > print_limit && !contextSrv.user.isGrafanaAdmin) {
                          appEvents.emit(AppEvents.alertWarning, [
                            'Report generation is limited to ' +
                              print_limit +
                              ' panels. Contact us for more information: ifctrl.com/contact',
                          ]);
                          return;
                        }

                        const { dashboard } = this.props;
                        const reportUrl =
                          API_URL.rpt_server +
                          '/generic_report/' +
                          dashboard.uid +
                          '/' +
                          contextSrv.user.orgId +
                          '/' +
                          token +
                          '?from=' +
                          dashboard.time.from +
                          '&to=' +
                          dashboard.time.to +
                          '&user_name=' +
                          contextSrv.user.login +
                          '&orgName=' +
                          contextSrv.user.orgName +
                          '_' +
                          contextSrv.user.orgId;

                        let paramStr = '';
                        let param: any;
                        for (param of dashboard.getVariables()) {
                          paramStr += paramStr + '&var-' + param.name + '=' + param.current.value;
                        }
                        console.log(reportUrl + paramStr);
                        console.log('opening window');
                        window.open(reportUrl + paramStr, '_blank');

                        return paramStr;
                      } else {
                        return appEvents.emit(AppEvents.alertSuccess, ['License limit reached!']);
                      }
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          appEvents.emit(AppEvents.alertSuccess, [
            'Contact us today about unlimited pdf reports from your dashboards!',
          ]);
          this.setState({
            blockDisplay: 'block',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //Check form validation ......
  checkValidField = (signUpData: any) => {
    let validName = /^[a-zA-Z\s]*$/;
    let validMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validPhone = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/;

    // Get the phone number the user entered and add a + if it doesn't exist
    var phoneToCheck = signUpData.phone;
    if (phoneToCheck[0] !== '+') {
      phoneToCheck = '+' + phoneToCheck;
    }

    if (signUpData.name === '') {
      this.setState({
        formError: 'Name field should not be blank !',
      });
      return false;
    } else if (!validName.test(signUpData.name)) {
      this.setState({
        formError: 'Name field contain only character value!',
      });
      return false;
    } else if (signUpData.email === '') {
      this.setState({
        formError: 'Email field should not be blank !',
      });
      return false;
    } else if (!validMail.test(signUpData.email)) {
      this.setState({
        formError: 'Please Entered valid Email address!',
      });
      return false;
    } else if (signUpData.phone === '') {
      this.setState({
        formError: 'Phone field should not be blank !',
      });
      return false;
    } else if (!validPhone.test(phoneToCheck)) {
      this.setState({
        formError: 'Please Entered valid Phone Number !',
      });
      return false;
    } else if (signUpData.company === '') {
      this.setState({
        formError: 'Company Name field should not be blank !',
      });
      return false;
    } else if (signUpData.industry === '') {
      this.setState({
        formError: 'Industry Name field should not be blank !',
      });
      return false;
    } else {
      this.setState({
        formError: '',
      });
      return true;
    }
  };

  generateLicense = async () => {
    const reporterAPIdata = {
      orgName: contextSrv.user.orgName + '_' + contextSrv.user.orgId,
      orgId: '' + contextSrv.user.orgId,
      limit: this.state.licenseLimit,
    };

    /*const signUpData = {
      name: this.state.name + " " + this.state.tireValue,
      email: this.state.email,
      phone: this.state.phone,
      company: this.state.companyName,
      industry: this.state.industry
    }*/

    const signUpData = {
      name: contextSrv.user.login + ' free',
      email: contextSrv.user.email,
      phone: '+15558675309',
      company: contextSrv.user.orgName,
      industry: '',
    };

    //call form validation function

    // if (await this.checkValidField(signUpData)) {
    await fetch(API_URL.automation_server + '/reporter_sign_up', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(signUpData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data && data.msg) {
          appEvents.emit(AppEvents.alertSuccess, [data.msg]);

          await fetch(API_URL.automation_server + '/register/reporter', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(reporterAPIdata),
          })
            .then((response) => response.json())
            .then(async (data) => {
              if (data && data.msg) {
                appEvents.emit(AppEvents.alertSuccess, [data.msg]);

                await fetch(API_URL.auth_server + '/getToken', {
                  method: 'POST', // *GET, POST, PUT, DELETE, etc.
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    oid: contextSrv.user.orgName + '_' + contextSrv.user.orgId,
                    serviceID: 'api_token',
                  }),
                })
                  .then((response) => response.json())
                  .then(async (data) => {
                    var access_token = '';
                    if (data && data.access_token) {
                      access_token = data.access_token;

                      console.log('one_shot');
                      const { dashboard } = this.props;
                      const reportUrl =
                        API_URL.rpt_server +
                        '/generic_report/' +
                        dashboard.uid +
                        '/' +
                        contextSrv.user.orgId +
                        '/' +
                        access_token +
                        '?from=' +
                        dashboard.time.from +
                        '&to=' +
                        dashboard.time.to +
                        '&user_name=' +
                        contextSrv.user.login +
                        '&orgName=' +
                        contextSrv.user.orgName +
                        '_' +
                        contextSrv.user.orgId;

                      let paramStr = '';
                      let param: any;
                      for (param of dashboard.getVariables()) {
                        paramStr += paramStr + '&var-' + param.name + '=' + param.current.value;
                      }
                      console.log(reportUrl + paramStr);
                      console.log('opening window');
                      window.open(reportUrl + paramStr, '_blank');
                    } else {
                      appEvents.emit(AppEvents.alertError, ['Did not get Token!']);
                    }
                  });
              } else {
                appEvents.emit(AppEvents.alertError, ['Something Went Wrong!']);
              }
            })
            .catch((error) => {
              appEvents.emit(AppEvents.alertError, ['Something Went Wrong!']);
            });
        }
      });
    // }
  };
  // changeTire = (event: FormEvent<HTMLInputElement>) => {
  //   if (event == 'free') {
  //     this.setState({
  //       licenseLimit: '15',
  //     });
  //   } else if (event == 'paid') {
  //     this.setState({
  //       licenseLimit: '15'
  //     });
  //   }
  //   this.setState({
  //     tireValue: event
  //   });
  // }
  renderRightActionsButton() {
    const { dashboard, onAddPanel, location, updateTimeZoneForSession } = this.props;
    const { showSettings, canSave } = dashboard.meta;
    const { snapshot } = dashboard;
    const snapshotUrl = snapshot && snapshot.originalUrl;
    const buttons: ReactNode[] = [];
    // const tvButton = (
    //   <ToolbarButton tooltip="Cycle view mode" icon="monitor" onClick={this.onToggleTVMode} key="tv-button" />
    // );

    let timeControls: React.ReactNode | null;

    if (!dashboard.timepicker.hidden) {
      timeControls = (
        <DashNavTimeControls
          dashboard={dashboard}
          location={location}
          onChangeTimeZone={updateTimeZoneForSession}
          key="time-controls"
        />
      );
    }

    if (this.isPlaylistRunning()) {
      return [this.renderPlaylistControls(), timeControls];
    }

    // if (this.isInKioskMode()) {
    //   return [timeControls, tvButton];
    // }

    // if (canEdit && !isFullscreen) {
    if (canSave) {
      buttons.push(<ToolbarButton tooltip="Add panel" icon="panel-add" onClick={onAddPanel} key="button-panel-add" />);
      buttons.push(
        <ModalsController key="button-save">
          {({ showModal, hideModal }) => (
            <ToolbarButton
              tooltip="Save dashboard"
              icon="save"
              onClick={() => {
                showModal(SaveDashboardModalProxy, {
                  dashboard,
                  onDismiss: hideModal,
                });
              }}
            />
          )}
        </ModalsController>
      );
    }

    if (snapshotUrl) {
      buttons.push(
        <ToolbarButton
          tooltip="Open original dashboard"
          onClick={() => this.gotoSnapshotOrigin(snapshotUrl)}
          icon="link"
          key="button-snapshot"
        />
      );
    }

    if (showSettings) {
      buttons.push(
        <ToolbarButton tooltip="Dashboard settings" icon="cog" onClick={this.onOpenSettings} key="button-settings" />
      );
    }

    if (dashboard.uid !== null && dashboard.meta.isHome !== true) {
      buttons.push(<ToolbarButton tooltip="Download report" icon="download-alt" onClick={this.generateReport} />);
    }

    /** Customer Support modal button */
    buttons.push(
      <ModalsController key="button-save">
        {({ showModal, hideModal }) => (
          <ToolbarButton
            tooltip="Customer Support"
            icon="question-circle"
            onClick={() => {
              showModal(SupportModal, {
                dashboard,
                onDismiss: hideModal,
              });
            }}
          />
        )}
      </ModalsController>
    );

    this.addCustomContent(customRightActions, buttons);
    buttons.push(timeControls);
    // buttons.push(tvButton);
    return buttons;
  }

  gotoSnapshotOrigin(snapshotUrl: string) {
    window.location.href = textUtil.sanitizeUrl(snapshotUrl);
  }

  render() {
    const { dashboard, isFullscreen } = this.props;
    const onGoBack = isFullscreen ? this.onClose : undefined;

    return (
      <>
        <div className="download-report-form" style={{ display: this.state.blockDisplay }}>
          <div className="subscription-form">
            <p className="closeIcon" onClick={this.cloaseForm}>
              <Icon name="fa fa-close" className="fa-times-circle-o" />
            </p>
            <h3>Virtual Reporter</h3>
            <p>
              This is a limited version of our Virtual Reporter functionality, allowing for Dashboards up to 10 panels
              to be rendered with a monthly limit of 10 PDF downloads. <br />
              If you would like to upgrade your account limits feel free to{' '}
              <a style={{ color: '#0e71f4' }} href="https://ifctrl.com/contact/" target="_blank" rel="noreferrer">
                contact us
              </a>
            </p>
            {/*             /*<div className="form-field-inline"> */
            /* }
{ */
            /*               <Field label="Select tier"> */
            /* }
{ */
            /*                 <RadioButtonGroup */
            /* }
{ */
            /*                   options={selectTireoptions} */
            /* }
{ */
            /*                   value={this.state.tireValue} */
            /* }
{ */
            /*                   onChange={this.changeTire} */
            /* }
{ */
            /*                 /> */
            /* }
{ */
            /*               </Field> */
            /* }
{ */
            /*             </div> */
            /* }
{ */
            /*             <div className="formField"> */
            /* }
{ */
            /*               <div className="form-field-inline"> */
            /* }
{ */
            /*                 <Field label="Name"> */
            /* }
{ */
            /*                   <Input name="name" type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} /> */
            /* }
{ */
            /*                 </Field> */
            /* }
{ */
            /*               </div> */
            /* }
{ */
            /*               <div className="form-field-inline"> */
            /* }
{ */
            /*                 <Field label="Email"> */
            /* }
{ */
            /*                   <Input name="email" type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} /> */
            /* }
{ */
            /*                 </Field> */
            /* }
{ */
            /*               </div> */
            /* }
{ */
            /*               <div className="form-field-inline"> */
            /* }
{ */
            /*                 <Field label="Phone"> */
            /* }
{ */
            /*                   <Input name="phone" type="text" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} /> */
            /* }
{ */
            /*                 </Field> */
            /* }
{ */
            /*               </div> */
            /* }
{ */
            /*               <div className="form-field-inline"> */
            /* }
{ */
            /*                 <Field label="Company name"> */
            /* }
{ */
            /*                   <Input name="companyName" type="text" value={this.state.companyName} onChange={(e) => this.setState({ companyName: e.target.value })} /> */
            /* }
{ */
            /*                 </Field> */
            /* }
{ */
            /*               </div> */
            /* }
{ */
            /*               <div className="form-field-inline"> */
            /* }
{ */
            /*                 <Field label="Industry"> */
            /* }
{ */
            /*                   <Input name="industry" type="text" value={this.state.industry} onChange={(e) => this.setState({ industry: e.target.value })} /> */
            /* }
{ */
            /*                 </Field> */
            /* }
{ */
            /*               </div> */
            /* }
{ */
            /*               <p className="errorMsg">{this.state.formError}</p> */
            /* }
{ */
            /*             </div> */}
            <div className="form-field-inline" style={{ textAlign: 'center' }}>
              <Button id="generate-license" variant="primary" size="lg" key="lg" onClick={this.generateLicense}>
                Generate License
              </Button>
            </div>
          </div>
        </div>
        <PageToolbar
          pageIcon={isFullscreen ? undefined : 'apps'}
          title={dashboard.title}
          parent={dashboard.meta.folderTitle}
          onClickTitle={this.onDashboardNameClick}
          onClickParent={this.onFolderNameClick}
          onGoBack={onGoBack}
          leftItems={this.renderLeftActionsButton()}
        >
          {this.renderRightActionsButton()}
        </PageToolbar>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  location: state.location,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  updateLocation,
  updateTimeZoneForSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashNav);
