import { DataFrame, DataTransformerInfo } from '../../types';
import { MonoTypeOperatorFunction, Observable, from, of } from 'rxjs';
import { MutableDataFrame, FieldType, AppEvents } from '@grafana/data'; // eslint-disable-line no-restricted-imports
// import appEvents from 'app/core/app_events';
import configData from '../../../../../api_config.json';

function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

const fetchResult = (url: any = '', data: any = {}): Observable<DataFrame[]> => {
  // Default options are marked with *
  var err = false;

  const obs: any = new Observable((observer) => {
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          err = true;
        }
        return response.json();
      })
      .then((transformedData) => {
        if (err) {
          console.log('ERRORED OUT');
          throw Error(transformedData.ERROR);
        }

        // if (Object.keys(transformedData).length === 0) {
        //   return appEvents.emit(AppEvents.alertError, ['No data available']);
        // }

        const frame = new MutableDataFrame({
          refId: create_UUID(),
          fields: [],
        });

        console.log(transformedData);

        //!(key === 'time') ? FieldType.number : FieldType.time
        Object.keys(transformedData).map(function (key, idx) {
          var field_type;

          console.log(key);
          if (key === 'time') {
            console.log("time");
            field_type = FieldType.time;
          } else if (key === 'label' || key === 'color') {
            console.log("string");
            field_type = FieldType.string;
          } else {
            console.log("number");
            field_type = FieldType.number;
          }

          frame.addField({
            name: key,
            type: field_type,
            values: transformedData[key],
          });
        });

        observer.next([frame]);
        observer.complete();
      })
      .catch((error) => {
        console.log(error);
        // appEvents.emit(AppEvents.alertError, [String(error)]);
      });
  });
  console.log(obs);
  return obs;
};

export interface AnalyticsTransformerOptions {
  server: string;
  //server: configData.analytics_server;
  //server: 'https://xfrm.ifctrl.net';
  _package: 'clustering';
  _package_desc: 'Automatically find groups of data relationships';

  _module: 'kmeans';
  _module_desc: 'Use a kmeans algorithm.';

  _function: 'perform';
  _function_desc: 'Performs a k-means clustering on the data.';
  table: [];
  params: {};
  packages: [];
  modules: [];
  functions: [];
  init: false;
  applyButtonHit: false;
}

export const analyticsTransformer: DataTransformerInfo<AnalyticsTransformerOptions> = {
  id: 'ifanalytics',
  name: 'IntelliFlux Analytics',
  description: 'Call the analytics API to transform data',
  defaultOptions: {
    server: configData.analytics_server,

    _package: 'clustering',
    _package_desc: 'Automatically find groups of data relationships',

    _module: 'kmeans',
    _module_desc: 'Use a kmeans algorithm.',

    _function: 'perform',
    _function_desc: 'Performs a k-means clustering on the data.',

    table: [],

    params: { k: 3 },
    packages: [
      { label: 'clustering', value: 'clustering' },
      { label: 'decomp', value: 'decomp' },
      { label: 'preprocessing', value: 'preprocessing' },
      { label: 'regression', value: 'regression' },
    ],
    modules: [{ label: 'kmeans', value: 'kmeans' }],
    functions: [{ label: 'perform', value: 'perform' }],
  },

  transformer: (options: any, dataFrames): any => {
    try {
      if (!dataFrames.length) {
        return dataFrames;
      }

      console.log(dataFrames);

      /** Convert values key to array */
      /**let convertedToArr: any = [];
      try {
        convertedToArr = dataFrames[0].fields[1].values.toArray();
      } catch (error) {
        convertedToArr = dataFrames[0].fields[1].values;
      }
      let newconvertedToArr: any = [];
      for (let i = 0; i < convertedToArr.length; i++) {
        newconvertedToArr.push(convertedToArr[i]);
      }
      dataFrames[0].fields[1].values = newconvertedToArr;**/

      /** Rename time and value */
      /**
      dataFrames[0].fields[0].name = 'Time';
      dataFrames[0].fields[1].name = 'Value';
      **/

      let url = new URL(options.server + '/lib/' + options._package + '/' + options._module + '/' + options._function);
      Object.keys(options.params).forEach((key) => url.searchParams.append(key, options.params[key.toString()]));

      if (options.applyButtonHit) {
        console.log('FETCHING TRANSFORMS');
        console.log(url);
        console.log(dataFrames);
        return fetchResult(url, dataFrames);
      } else {
        console.log('GOT HERE');
        return of(dataFrames);
      }
    } catch {
      console.log('GOT HERE 2');
      return from(dataFrames);
    }
  },
  operator: function (options): MonoTypeOperatorFunction<DataFrame[]> {
    throw new Error('Function not implemented.');
  },
};
