import React, { Component } from 'react';
import { Select, Table } from '@grafana/ui';
import {
  DataFrame,
  FieldMatcherID,
  getFrameDisplayName,
  PanelProps,
  SelectableValue,
  toDataFrame,
  FieldType,
} from '@grafana/data';
import { Options } from './types';
import { css } from 'emotion';
import { config } from 'app/core/config';
import { FilterItem, TableSortByFieldState } from '@grafana/ui/src/components/Table/types';
import { dispatch } from '../../../store/store';
import { applyFilterFromTable } from '../../../features/variables/adhoc/actions';
import { getDashboardSrv } from '../../../features/dashboard/services/DashboardSrv';
import api_config from '../../../../../api_config.json';

interface Props extends PanelProps<Options> {}

interface State {
  modifiedData: any;
}

export class EZCorrelation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let count = props.data.series?.length;
    const hasFields = props.data.series[0]?.fields.length;

    let copy_data = null;
    const { data, options } = props;

    if (count && hasFields) {
      if (count > 1) {
        const count = data.series?.length;
        const currIndex = options.frameIndex > 0 && options.frameIndex < count ? options.frameIndex : 0;

        copy_data = toDataFrame(data.series[currIndex]);
      } else {
        copy_data = toDataFrame(data.series[0]);
      }
    }

    this.state = {
      modifiedData: copy_data,
    };
  }

  onColumnResize = (fieldDisplayName: string, width: number) => {
    const { fieldConfig } = this.props;
    const { overrides } = fieldConfig;

    const matcherId = FieldMatcherID.byName;
    const propId = 'custom.width';

    // look for existing override
    const override = overrides.find((o) => o.matcher.id === matcherId && o.matcher.options === fieldDisplayName);

    if (override) {
      // look for existing property
      const property = override.properties.find((prop) => prop.id === propId);
      if (property) {
        property.value = width;
      } else {
        override.properties.push({ id: propId, value: width });
      }
    } else {
      overrides.push({
        matcher: { id: matcherId, options: fieldDisplayName },
        properties: [{ id: propId, value: width }],
      });
    }

    this.props.onFieldConfigChange({
      ...fieldConfig,
      overrides,
    });
  };

  onSortByChange = (sortBy: TableSortByFieldState[]) => {
    this.props.onOptionsChange({
      ...this.props.options,
      sortBy,
    });
  };

  onChangeTableSelection = (val: SelectableValue<number>) => {
    this.props.onOptionsChange({
      ...this.props.options,
      frameIndex: val.value || 0,
    });

    // Force a redraw -- but no need to re-query
    this.forceUpdate();
  };

  onCellFilterAdded = (filter: FilterItem) => {
    const { key, value, operator } = filter;
    const panelModel = getDashboardSrv().getCurrent().getPanelById(this.props.id);
    const datasource = panelModel?.datasource;

    if (!datasource) {
      return;
    }

    dispatch(applyFilterFromTable({ datasource, key, operator, value }));
  };

  componentDidMount() {
    console.log('!!!Data Changed!!!');

    let count = this.props.data.series?.length;
    const hasFields = this.props.data.series[0]?.fields.length;

    if (!count || !hasFields) {
      this.setState({ modifiedData: null });
      return;
    }

    let currentIndex = 0;
    if (count > 1) {
      currentIndex = this.getCurrentFrameIndex();
    }

    console.log(api_config.analytics_server + '/lib/preprocessing/scaling/standardize');

    // Do data correlation here
    let standardizeUrl = new URL(api_config.analytics_server + '/lib/preprocessing/scaling/standardize').toString();
    let origData = this.props.data.series[currentIndex];
    console.log(origData);
    console.log(JSON.stringify(origData));

    fetch(standardizeUrl, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify([origData]),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Got Data Standardize!');
        console.log(data);

        let newFields = [];

        for (let key in data) {
          let fieldType = FieldType.number;

          if (key === 'Time' || key === 'time') {
            fieldType = FieldType.time;
          } else {
            fieldType = origData.fields.find((field) => field.name === key)!.type;
          }

          newFields.push({ name: key, type: fieldType, values: data[key] });
        }

        console.log('Fields!');
        console.log(newFields);

        let newDataFrame = {
          name: origData.name,
          fields: newFields,
        };

        let modFrame = toDataFrame(newDataFrame);
        //const fieldConfig = {"defaults":{"custom":{"align":null,"filterable":false},"mappings":[],"thresholds":{"mode":"absolute","steps":[{"color":"green","value":null},{"color":"red","value":80}]}},"overrides":[{"matcher":{"id":"byType","options":"number"},"properties":[{"id":"custom.displayMode","value":"color-background"},{"id":"decimals","value":3},{"id":"thresholds","value":{"mode":"absolute","steps":[{"color":"red","value":null},{"color":"light-red","value":-0.75},{"color":"light-orange","value":-0.5},{"color":"rgb(247, 219, 172)","value":-0.25},{"color":"rgb(219, 219, 219)","value":0},{"color":"rgb(167, 219, 155)","value":0.25},{"color":"light-green","value":0.5},{"color":"green","value":0.75},{"color":"rgb(105, 105, 105)","value":1}]}}]},{"matcher":{"id":"byName","options":"_"},"properties":[{"id":"custom.displayMode"}]}]}
        //modFrame = applyFieldOverrides({data: [modFrame], fieldConfig: fieldConfig})[0];
        console.log(modFrame);

        console.log('Pushing modded frame');

        //this.setState({modifiedData: modFrame});

        let correlationUrl = new URL(
          api_config.analytics_server + '/lib/analysis/statistics/correlation?method=pearson&min_periods=1'
        ).toString();

        fetch(correlationUrl, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify([modFrame]),
        })
          .then((response) => response.json())
          .then((data_correlation) => {
            console.log('Got Data Correlation!');
            console.log(data_correlation);

            let newFields_correlation = [];

            for (let key in data_correlation) {
              let fieldType_correlation = FieldType.number;

              if (key === '_') {
                fieldType_correlation = FieldType.string;
              }

              if (key === '_') {
                newFields_correlation.push({ name: key, type: fieldType_correlation, values: data_correlation[key] });
              } else {
                let number_conf = {
                  custom: {
                    filterable: false,
                    displayMode: 'color-background',
                  },
                  thresholds: {
                    mode: 'absolute',
                    steps: [
                      {
                        color: 'semi-dark-red',
                        value: null,
                      },
                      {
                        color: 'red',
                        value: -0.75,
                      },
                      {
                        value: -0.5,
                        color: 'orange',
                      },
                      {
                        value: -0.25,
                        color: 'rgb(251, 219, 138)',
                      },
                      {
                        value: 0,
                        color: 'rgb(226, 226, 226)',
                      },
                      {
                        value: 0.25,
                        color: 'light-green',
                      },
                      {
                        value: 0.5,
                        color: 'semi-dark-green',
                      },
                      {
                        value: 0.75,
                        color: 'dark-green',
                      },
                      {
                        value: 1,
                        color: 'rgb(75, 75, 74)',
                      },
                    ],
                  },
                  color: {
                    mode: 'thresholds',
                  },
                };

                newFields_correlation.push({
                  name: key,
                  type: fieldType_correlation,
                  values: data_correlation[key],
                  config: number_conf,
                });
              }
            }

            console.log('Fields!');
            console.log(newFields_correlation);

            let newDataFrame_correlation = {
              name: modFrame.name,
              fields: newFields_correlation,
            };

            let modFrame_correlation = toDataFrame(newDataFrame_correlation);
            //"defaults":{"custom":{"align":null,"filterable":false},"mappings":[],"thresholds":{"mode":"absolute","steps":[{"color":"green","value":null},{"color":"red","value":80}]}},
            //const fieldConfig = {"overrides":[{"matcher":{"id":"byType","options":"number"},"properties":[{"id":"custom.displayMode","value":"color-background"},{"id":"decimals","value":3},{"id":"thresholds","value":{"mode":"absolute","steps":[{"color":"red","value":null},{"color":"light-red","value":-0.75},{"color":"light-orange","value":-0.5},{"color":"rgb(247, 219, 172)","value":-0.25},{"color":"rgb(219, 219, 219)","value":0},{"color":"rgb(167, 219, 155)","value":0.25},{"color":"light-green","value":0.5},{"color":"green","value":0.75},{"color":"rgb(105, 105, 105)","value":1}]}}]},{"matcher":{"id":"byName","options":"_"},"properties":[{"id":"custom.displayMode"}]}]}
            //modFrame_correlation = applyFieldOverrides({data: [modFrame_correlation], fieldConfig: fieldConfig})[0];
            console.log(modFrame_correlation);

            console.log('Pushing modded frame');

            this.setState({ modifiedData: modFrame_correlation });
          })
          .catch((error) => {
            console.log('Error!');
            console.log(error);
            console.log('Pushing orgininal frame');

            let countErrorNew = this.props.data.series?.length;

            let currentIndexErrorNew = 0;
            if (countErrorNew > 1) {
              currentIndexErrorNew = this.getCurrentFrameIndex();
            }

            this.setState({ modifiedData: this.props.data.series[currentIndexErrorNew] });
          });
      })
      .catch((error) => {
        console.log('Error!');
        console.log(error);
        console.log('Pushing orgininal frame');

        let countError = this.props.data.series?.length;

        let currentIndexError = 0;
        if (countError > 1) {
          currentIndexError = this.getCurrentFrameIndex();
        }

        this.setState({ modifiedData: this.props.data.series[currentIndexError] });
      });
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.data.series !== this.props.data.series ||
      prevProps.options.frameIndex !== this.props.options.frameIndex
    ) {
      console.log('!!!Data Changed!!!');

      let count = this.props.data.series?.length;
      const hasFields = this.props.data.series[0]?.fields.length;

      if (!count || !hasFields) {
        this.setState({ modifiedData: null });
        return;
      }

      let currentIndex = 0;
      if (count > 1) {
        currentIndex = this.getCurrentFrameIndex();
      }

      console.log(api_config.analytics_server + '/lib/preprocessing/scaling/standardize');

      // Do data correlation here
      let standardizeUrl = new URL(api_config.analytics_server + '/lib/preprocessing/scaling/standardize').toString();
      let origData = this.props.data.series[currentIndex];
      console.log(origData);
      console.log(JSON.stringify(origData));

      fetch(standardizeUrl, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify([origData]),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Got Data Standardize!');
          console.log(data);

          let newFields = [];

          for (let key in data) {
            let fieldType = FieldType.number;

            if (key === 'Time' || key === 'time') {
              fieldType = FieldType.time;
            } else {
              fieldType = origData.fields.find((field) => field.name === key)!.type;
            }

            newFields.push({ name: key, type: fieldType, values: data[key] });
          }

          console.log('Fields!');
          console.log(newFields);

          let newDataFrame = {
            name: origData.name,
            fields: newFields,
          };

          let modFrame = toDataFrame(newDataFrame);
          //const fieldConfig = {"defaults":{"custom":{"align":null,"filterable":false},"mappings":[],"thresholds":{"mode":"absolute","steps":[{"color":"green","value":null},{"color":"red","value":80}]}},"overrides":[{"matcher":{"id":"byType","options":"number"},"properties":[{"id":"custom.displayMode","value":"color-background"},{"id":"decimals","value":3},{"id":"thresholds","value":{"mode":"absolute","steps":[{"color":"red","value":null},{"color":"light-red","value":-0.75},{"color":"light-orange","value":-0.5},{"color":"rgb(247, 219, 172)","value":-0.25},{"color":"rgb(219, 219, 219)","value":0},{"color":"rgb(167, 219, 155)","value":0.25},{"color":"light-green","value":0.5},{"color":"green","value":0.75},{"color":"rgb(105, 105, 105)","value":1}]}}]},{"matcher":{"id":"byName","options":"_"},"properties":[{"id":"custom.displayMode"}]}]}
          //modFrame = applyFieldOverrides({data: [modFrame], fieldConfig: fieldConfig})[0];
          console.log(modFrame);

          console.log('Pushing modded frame');

          //this.setState({modifiedData: modFrame});

          let correlationUrl = new URL(
            api_config.analytics_server + '/lib/analysis/statistics/correlation?method=pearson&min_periods=1'
          ).toString();

          fetch(correlationUrl, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify([modFrame]),
          })
            .then((response) => response.json())
            .then((data_correlation) => {
              console.log('Got Data Correlation!');
              console.log(data_correlation);

              let newFields_correlation = [];

              for (let key in data_correlation) {
                let fieldType_correlation = FieldType.number;

                if (key === '_') {
                  fieldType_correlation = FieldType.string;
                }

                if (key === '_') {
                  newFields_correlation.push({ name: key, type: fieldType_correlation, values: data_correlation[key] });
                } else {
                  let number_conf = {
                    custom: {
                      filterable: false,
                      displayMode: 'color-background',
                    },
                    thresholds: {
                      mode: 'absolute',
                      steps: [
                        {
                          color: 'semi-dark-red',
                          value: null,
                        },
                        {
                          color: 'red',
                          value: -0.75,
                        },
                        {
                          value: -0.5,
                          color: 'orange',
                        },
                        {
                          value: -0.25,
                          color: 'rgb(251, 219, 138)',
                        },
                        {
                          value: 0,
                          color: 'rgb(226, 226, 226)',
                        },
                        {
                          value: 0.25,
                          color: 'light-green',
                        },
                        {
                          value: 0.5,
                          color: 'semi-dark-green',
                        },
                        {
                          value: 0.75,
                          color: 'dark-green',
                        },
                        {
                          value: 1,
                          color: 'rgb(75, 75, 74)',
                        },
                      ],
                    },
                    color: {
                      mode: 'thresholds',
                    },
                  };

                  newFields_correlation.push({
                    name: key,
                    type: fieldType_correlation,
                    values: data_correlation[key],
                    config: number_conf,
                  });
                }
              }

              console.log('Fields!');
              console.log(newFields_correlation);

              let newDataFrame_correlation = {
                name: modFrame.name,
                fields: newFields_correlation,
              };

              let modFrame_correlation = toDataFrame(newDataFrame_correlation);
              //"defaults":{"custom":{"align":null,"filterable":false},"mappings":[],"thresholds":{"mode":"absolute","steps":[{"color":"green","value":null},{"color":"red","value":80}]}},
              //const fieldConfig = {"overrides":[{"matcher":{"id":"byType","options":"number"},"properties":[{"id":"custom.displayMode","value":"color-background"},{"id":"decimals","value":3},{"id":"thresholds","value":{"mode":"absolute","steps":[{"color":"red","value":null},{"color":"light-red","value":-0.75},{"color":"light-orange","value":-0.5},{"color":"rgb(247, 219, 172)","value":-0.25},{"color":"rgb(219, 219, 219)","value":0},{"color":"rgb(167, 219, 155)","value":0.25},{"color":"light-green","value":0.5},{"color":"green","value":0.75},{"color":"rgb(105, 105, 105)","value":1}]}}]},{"matcher":{"id":"byName","options":"_"},"properties":[{"id":"custom.displayMode"}]}]}
              //modFrame_correlation = applyFieldOverrides({data: [modFrame_correlation], fieldConfig: fieldConfig})[0];
              console.log(modFrame_correlation);

              console.log('Pushing modded frame');

              this.setState({ modifiedData: modFrame_correlation });
            })
            .catch((error) => {
              console.log('Error!');
              console.log(error);
              console.log('Pushing orgininal frame');

              let countErrorNew = this.props.data.series?.length;

              let currentIndexErrorNew = 0;
              if (countErrorNew > 1) {
                currentIndexErrorNew = this.getCurrentFrameIndex();
              }

              this.setState({ modifiedData: this.props.data.series[currentIndexErrorNew] });
            });
        })
        .catch((error) => {
          console.log('Error!');
          console.log(error);
          console.log('Pushing orgininal frame');

          let countError = this.props.data.series?.length;

          let currentIndexError = 0;
          if (countError > 1) {
            currentIndexError = this.getCurrentFrameIndex();
          }

          this.setState({ modifiedData: this.props.data.series[currentIndexError] });
        });
    }
  }

  renderTable(frame: DataFrame, width: number, height: number) {
    const { options } = this.props;

    return (
      <Table
        height={height}
        width={width}
        data={frame}
        noHeader={!options.showHeader}
        resizable={true}
        initialSortBy={options.sortBy}
        onSortByChange={this.onSortByChange}
        onColumnResize={this.onColumnResize}
        onCellFilterAdded={this.onCellFilterAdded}
      />
    );
  }

  getCurrentFrameIndex() {
    const { data, options } = this.props;
    const count = data.series?.length;
    return options.frameIndex > 0 && options.frameIndex < count ? options.frameIndex : 0;
  }

  render() {
    console.log('!!!Rendering!!!');

    const { data, height, width } = this.props;

    const count = data.series?.length;
    const hasFields = data.series[0]?.fields.length;

    if (!count || !hasFields) {
      return <div>No data</div>;
    }

    if (count > 1) {
      const inputHeight = config.theme.spacing.formInputHeight;
      const padding = 8 * 2;
      const currentIndex = this.getCurrentFrameIndex();
      const names = data.series.map((frame, index) => {
        return {
          label: getFrameDisplayName(frame),
          value: index,
        };
      });

      //data.series[currentIndex]
      return (
        <div className={tableStyles.wrapper}>
          {this.renderTable(this.state.modifiedData, width, height - inputHeight - padding)}
          <div className={tableStyles.selectWrapper}>
            <Select options={names} value={names[currentIndex]} onChange={this.onChangeTableSelection} />
          </div>
        </div>
      );
    }

    return this.renderTable(this.state.modifiedData, width, height - 12);
  }
}

const tableStyles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  selectWrapper: css`
    padding: 8px;
  `,
};
