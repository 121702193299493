import React, { PureComponent } from 'react';
import appEvents from '../../app_events';
import TopSection from './TopSection';
import BottomSection from './BottomSection';
import config from 'app/core/config';
import { CoreEvents } from 'app/types';
import { Branding } from 'app/core/components/Branding/Branding';
import { Icon } from '@grafana/ui';
import { Tooltip } from '@grafana/ui';
import CookieConsent from "react-cookie-consent";

const homeUrl = config.appSubUrl || '/';

export class SideMenu extends PureComponent {
  toggleSideMenuSmallBreakpoint = () => {
    appEvents.emit(CoreEvents.toggleSidemenuMobile);
  };

  render() {
    return [
      <Tooltip content={"Go to home"} >
        <a href={homeUrl} className="sidemenu__logo" key="logo">
          <Branding.MenuLogo />
        </a>
      </Tooltip>,
      <div className="sidemenu__logo_small_breakpoint" onClick={this.toggleSideMenuSmallBreakpoint} key="hamburger">
        <Icon name="bars" size="xl" />
        <span className="sidemenu__close">
          <Icon name="times" />
          &nbsp;Close
        </span>
      </div>,
      <TopSection key="topsection" />,
      <BottomSection key="bottomsection" />,
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="cookieConsent"
        containerClasses="cookie-container"
        buttonClasses="cookie-accept-btn"
        declineButtonClasses="cookie-decline-btn"
        buttonWrapperClasses="cookie-btn-wrapper"
        // onAccept={() => {

        // }}
        onDecline={() => {
          window.open("https://ifctrl.com", '_blank');
        }}
        expires={150}
      >
        <p>
          We use cookies to enhance your browsing experience, serve personalized ads or content, and anlyze our traffic. By clicking “Accept”, you consent to our use of cookies as described in our <a href="https://ifctrl.com/cookie-policy/" target="_blank" style={{ color: "black", textDecoration: "underline" }}>Cookie Policy.</a>
        </p>
      </CookieConsent>
    ];
  }
}
